import React from "react";

function Header(){
    return (
      <header>
        <h1 className="title">Sopa de Letras</h1>
        <h2 className="subtitle">Linguagens Script - 2022</h2>
      </header>
    );
}

export default Header;