import React from "react";
import "./footer.css";

function Footer() {
  return (
    <footer>
      <p>© D E I S @ I S EC </p>
      <p>Paulo Gouveia | Nuno Santos | Pedro Nogueira</p>
    </footer>
  );
}
export default Footer;
