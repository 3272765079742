export const palavras = [
  "SCRIPT",
  "VINTE",
  "NUNO",
  "PAULO",
  "NOGUEIRA",
  "PRAXE",
  "COIMBRA",
  "SERENATA",
  "ENGENHARIA",
  "ISEC",
  "EXAMES",
  "FREQUENCIAS",
  "RONALDO",
  "PORTUGAL",
  "FITAS",
  "QUEIMA",
  "ROMA",
  "DESENVOLVIMENTO",
];

export const TIMEOUT = 100;


